import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
	createMuiTheme,
	ThemeProvider,
	makeStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import { ThemeContext } from "../../../context/theme";
import Navigator from "./Navigator";
import Header from "./Header";
import Cookies from "js-cookie";

function PrivateContainer({ component: Component, ...props }) {
	const [appTheme, setAppTheme] = useContext(ThemeContext);
	const [mobileOpen, setMobileOpen] = React.useState(false);

	let theme = createMuiTheme({
		palette: {
			mode: appTheme,
			primary: {
				light: appTheme === 'light' ? "#63ccff" : '#88B4F6',
				main: appTheme === 'light' ? "#009be5" : '#88B4F6',
				dark: appTheme === 'light' ? "#006db3" : '#669DF7',
				contrastText: appTheme === 'light' ? undefined : '#212023',
			},
			background: {
				paper: appTheme === 'light'? 'white': '#212023',
			},
			action: {
				disabled: appTheme === 'light'? 'rgba(0, 0, 0, 0.38)': 'rgba(255, 255, 255, 0.38)',
				disabledBackground: appTheme === 'light'? 'rgba(0, 0, 0, 0.12)': 'rgba(255, 255, 255, 0.12)',
			},
		},
		typography: {
			h5: {
				fontWeight: 500,
				fontSize: 26,
				letterSpacing: 0.5,
			},
		},
		shape: {
			borderRadius: 8,
		},
		props: {
			MuiTab: {
				disableRipple: true,
			},
		},
		mixins: {
			toolbar: {
				minHeight: 48,
			},
		},
	});

	theme = {
		...theme,
		overrides: {
			MuiTypography: {
				h1: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
				h2: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				h3: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				h5: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				h4: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				h6: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				colorTextSecondary: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
				body1: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				gutterBottom: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
				root: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				}
			},
			MuiTableCell: {
				head: {
					color: appTheme === 'light' ? 'black' : '#E9EBED',
				},
				body: {
					color: appTheme === 'light' ? 'black' : '#E9EBED',
				},
				root: {
					borderBottom: appTheme === 'light'? '1px solid rgba(224, 224, 224, 1)' : '1px solid #000101',
				}
			},
			MuiFormHelperText: {
				root: {
					color: appTheme === 'light'? 'rgba(0, 0, 0, 0.54)': 'rgba(255, 255, 255, 0.54)',
				},
			},
			MuiFormControlLabel: {
				root: {
					color: appTheme === 'light' ? 'black !important' : '#E9EBED !important',
				},
			},
			MuiFormLabel: {
				root: {
					color: appTheme === 'light' ? 'black !important' : '#E9EBED !important',
				},
				disabled: {
					color: appTheme === 'light'? 'rgba(0, 0, 0, 0.38)': 'rgba(255, 255, 255, 0.38)',
				}
			},
			MuiCheckbox: {
				root: {
					color: appTheme === 'light' ? 'black !important' : '#E9EBED !important',
				}
			},
			MuiRadio: {
				disabled: {
					color: 'rgba(0, 0, 0, 0.38) !important'
				},
				root: {
					color: appTheme === 'light' ? 'black !important' : '#E9EBED !important',
				}
			},
			MuiInputLabel: {
				root: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
			},
			MuiGrid: {
				item: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				}
			},
			MuiInput: {
				disabled: {
					color: appTheme === 'light'? 'rgba(0, 0, 0, 0.38)': 'rgba(255, 255, 255, 0.38)',
				}
			},
			MuiPickersDay: {
				day: {
					color: appTheme === 'light' ? 'black' : '#E9EBED',
				},
			},
			MuiPickersClockNumber: {
				clockNumber: {
					color: appTheme === 'light' ? 'black' : '#E9EBED',
				}
			},
			MuiPickersCalendarHeader: {
				dayLabel: {
					color: appTheme === 'light' ? 'black' : '#E9EBED',
				}
			},
			MuiAccordion: {
				root: {
					background: appTheme === 'light' ? 'inherit': '#2A2A2F',
					padding: '5px',
				}
			},
			MuiSelect: {
				root: {
					background: 'transparent',
				},
				filled: {
					borderBottom: appTheme === 'light' ? '0px' : '1px solid #E9EBED',
				},
				select: {
					borderBottom: appTheme === 'light' ? '0px' : '1px solid #E9EBED',
				},
				outlined: {
					padding: '18.5px 14px !important',
				},
			},
			MuiInputBase: {
				disabled: {
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
					borderBottom: appTheme === 'light' ? '0px' : '1px solid #E9EBED',
					opacity: 0.8,
					cursor: 'not-allowed',
				},
				inputMarginDense: {
					background: 'inherit',
					borderBottom: appTheme === 'light' ? '0px' : '1px solid #E9EBED',
					padding: '10.5px 10.5px !important',
				},
				inputAdornedStart: {
					background: appTheme === 'light' ? 'inherit': 'transparent',
					borderBottom: appTheme === 'light' ? '0px' : '1px solid #E9EBED',
				},
				inputAdornedEnd: {
					background: appTheme === 'light' ? 'inherit': 'transparent',
					borderBottom: appTheme === 'light' ? '0px' : '1px solid #E9EBED',
				},
				input: {
					background: appTheme === 'light' ? 'inherit': '#2A2A2F',
					color: appTheme === 'light' ? 'inherit' : '#E9EBED',
				},
			},
			MuiAppBar: {
				root: {
					color: (appTheme === 'light' ? 'white' : '#212023') + ' !important',
					backgroundColor: (appTheme === 'light' ? '#009be5' : '#88b4f6') + ' !important',
				},
			},
			MuiDrawer: {
				paper: {
					backgroundColor: "#18202c !important",
				},
			},
			MuiLink: {
				root: {
					color: appTheme === 'light' ? undefined : '#88B4F6',
				}
			},
			MuiTablePagination: {
				caption: {
					color: appTheme === 'light'? 'black': '#E9EBED',
				},
				actions: {
					color: appTheme === 'light'? 'black': '#E9EBED',
				},
			},
			MuiPaginationItem: {
				root: {
					color: appTheme === 'light'? 'black': '#E9EBED',
				},
			},
			MuiButton: {
				text: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
				label: {
					textTransform: "none",
				},
				contained: {
					boxShadow: "none",
					"&:active": {
						boxShadow: "none",
					},
				},
			},
			MuiTabs: {
				root: {
					marginLeft: theme.spacing(1),
				},
				indicator: {
					height: 3,
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
					backgroundColor: theme.palette.common.white,
				},
			},
			MuiTab: {
				root: {
					textTransform: "none",
					margin: "0 16px",
					minWidth: 0,
					padding: 0,
					[theme.breakpoints.up("md")]: {
						padding: 0,
						minWidth: 0,
					},
				},
			},
			MuiMenuItem: {
				root: {
					color: appTheme === 'light'? 'inherit': '#E9EBED',
				}
			},
			MuiIconButton: {
				root: {
					padding: theme.spacing(1),
					color: appTheme === 'light'? 'black': '#E9EBED',
				},
				colorPrimary: {
					color: appTheme === 'light'? '#009be5': '#88B4F6',
				},
				// label: {
				// 	color: appTheme === 'light'? 'inherit': '#E9EBED',
				// },
			},
			MuiInputAdornment: {
				root: {
					color: appTheme === 'light'? 'inherit': '#E9EBED',
				}
			},
			MuiTooltip: {
				tooltip: {
					borderRadius: 4,
				},
			},
			MuiDivider: {
				root: {
					backgroundColor: appTheme === 'light' ? "#404854" : '#404854',
				},
			},
			MuiListItemText: {
				primary: {
					fontWeight: theme.typography.fontWeightMedium,
				},
			},
			MuiListItemIcon: {
				root: {
					color: "inherit",
					marginRight: 0,
					"& svg": {
						fontSize: 20,
					},
				},
			},
			MuiAvatar: {
				root: {
					width: 32,
					height: 32,
				},
			},
		},
	};

	const drawerWidth = 256;

	const useStyles = makeStyles({
		root: {
			display: "flex",
			minHeight: "100vh",
		},
		drawer: {
			[theme.breakpoints.up("sm")]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		app: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
		},
		main: {
			flex: 1,
			padding: theme.spacing(6, 4),
			background: appTheme === 'light' ? "#eaeff1" : '#2F3033',
		},
		footer: {
			padding: theme.spacing(2),
			background: appTheme === 'light' ? "#eaeff1" : '#2F3033',
		},
	});
	const classes = useStyles();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		const user = Cookies.get("coinpass_user")
			? Cookies.get("coinpass_user")
			: null;
		const access_token = Cookies.get("coinpass_user_access_token");
		if (!user && !access_token) props.history.push("/");
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<CssBaseline />
				<nav className={classes.drawer}>
					<Hidden smUp implementation="js">
						<Navigator
							PaperProps={{ style: { width: drawerWidth } }}
							variant="temporary"
							open={mobileOpen}
							onClose={handleDrawerToggle}
						/>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Navigator PaperProps={{ style: { width: drawerWidth } }} />
					</Hidden>
				</nav>
				<div className={classes.app}>
					<Header
						title={typeof props.title === "string" ? props.title : props.title[props.match.params.id]}
						history={props.history}
						onDrawerToggle={handleDrawerToggle}
					/>
					<main className={classes.main}>
						<Component {...props} />
					</main>
				</div>
			</div>
		</ThemeProvider>
	);
}

PrivateContainer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default PrivateContainer;
