import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./tailwind.output.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import ThemeProvider from "./context/theme";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import ErrorBoundary from "./components/errors/ErrorBoundary";

Amplify.configure(awsExports);

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary fallback={<p>Something went wrong</p>}>
			<ThemeProvider>
				<Provider store={store}>
					<App />
				</Provider>
			</ThemeProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
