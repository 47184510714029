import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Input,
  FormControl,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import UserSessionApi from "../../api/SessionApi";
import debounce from "debounce";
import GenerateReportModal from "../global/modal/GenerateReportModal";
import TableFilters from "../global/filters/TableFilters";
import { getLastYearISO } from "../../utils/Helpers";
import Alert from "@material-ui/lab/Alert";
import { ThemeContext } from "../../context/theme";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    position: "absolute",
    width: "auto",
    height: "auto",
    minWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: "10px",
    overflow: "auto",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  progress: {
    height: "20px",
  },
  divider: {
    margin: "20px 0",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const RestrictedUserSessions = () => {
  const [sessions, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState({
    search: "",
    page: 0,
    rows_per_page: 10,
    country: "all",
    device: "all",
    city: "all",
    type: "SignIn",
    restricted_countries: true,
    from: getLastYearISO(),
    to: new Date().toISOString(),
  });
  const [filters, setFilters] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    result: false,
    message: "",
  });

  const classes = useStyles();
  const [appTheme] = useContext(ThemeContext);

  const fetchData = async () => {
    setLoader(true);
    let data = {};
    try {
      ({ data } = await UserSessionApi.getSessions({
        ...query,
        page: query.page + 1,
      }));

      setData(data.paginated.data);
      setTotal(data.paginated.total);
    } catch (error) {
      setSnackBar((prev) => ({
        ...prev,
        open: true,
        result: false,
        message: error.message || "An Error Occurred",
      }));
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const fetchFilters = async () => {
    let data = {};
    const payload = {};
    if (
      query.country &&
      query.country?.trim() != "" &&
      query.country?.trim() != "all"
    ) {
      payload["country"] = query.country;
    }

    try {
      ({ data } = await UserSessionApi.getSessionFilters(payload));
    } catch (error) {
      console.log(error);
    }

    setFilters([
      {
        type: "select",
        key: "city",
        value: query.city,
        items: (data.cities || []).filter((i) => i).map((i) => ({ value: i })),
        key_value: "value",
      },
      {
        type: "select",
        key: "country",
        value: query.country,
        items: (data.countries || [])
          .filter((i) => i)
          .map((i) => ({ value: i })),
        key_value: "value",
      },
      {
        type: "select",
        key: "device",
        value: query.device,
        items: (data.devices || []).filter((i) => i).map((i) => ({ value: i })),
        key_value: "value",
      },
      {
        key: "from",
        value: query.from,
        label: "From",
        show: true,
        type: "date_picker",
        format: "dd/MM/yyyy",
      },
      {
        key: "to",
        value: query.to,
        show: true,
        label: "To",
        type: "date_picker",
        format: "dd/MM/yyyy",
      },
    ]);
  };

  const [generateReport, setGenerateReport] = useState({
    filename: "User Sessions.csv",
    open: false,
    headers: [],
  });

  useEffect(() => {
    fetchData();
    fetchFilters();
  }, [query]);

  const handleChangePage = (event, newPage) => {
    setQuery((prevState) => {
      return {
        ...prevState,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    event.persist();
    setQuery((prevState) => {
      return {
        ...prevState,
        per_page: parseInt(event.target.value, 10),
        page: 0,
      };
    });
  };

  const handleQueryChange = (field, e) => {
    let value = null;
    switch (field.type) {
      case "date_picker":
        value = e;
        break;
      default:
        value = e.target.value;
    }
    setQuery((prev) => ({
      ...prev,
      [field.key]: value,
    }));
  };

  const searchEvent = (event) => {
    searchQuery(event.target.value);
  };

  const searchQuery = debounce((val) => {
    setQuery((prevState) => {
      return {
        ...prevState,
        search: val,
        page: 0,
      };
    });
  }, 1000);

  const handleDownloadReport = async (e) => {
    setGenerateReport({ ...generateReport, open: true });
  };

  const handleCloseGenerateReport = () => {
    setGenerateReport({ ...generateReport, open: false });
  };

  const handleSnackbarClose = () => {
    setSnackBar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Paper>
      <TableContainer component={Paper}>
        <div
          className={
            "flex content-center flex-wrap h-20 p-6" +
            (appTheme === "light" ? " bg-gray-200" : " bg-gray-800")
          }
        >
          <FormControl fullWidth>
            <Input
              id="standard-adornment-amount"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon></SearchIcon>
                </InputAdornment>
              }
              className="w-full"
              onChange={searchEvent}
            />
          </FormControl>
        </div>
        <TableFilters
          filters={filters}
          extra_buttons={[]}
          handleQueryChange={handleQueryChange}
        />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User Identification</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Event Response</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Device Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Timezone</TableCell>
              <TableCell>Risk Decision</TableCell>
              <TableCell>Date & Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((row) => (
              <TableRow key={row.id} scope="row">
                <TableCell component="th">
                  {row.user?.user_identification || row.userId}
                </TableCell>
                <TableCell component="th">{row.eventType}</TableCell>
                <TableCell component="th">{row.eventResponse}</TableCell>
                <TableCell component="th">{row.ipAddress}</TableCell>
                <TableCell component="th">{row.deviceName}</TableCell>
                <TableCell component="th">{row.city}</TableCell>
                <TableCell component="th">{row.country}</TableCell>
                <TableCell component="th">{row.timezone}</TableCell>
                <TableCell component="th">{row.risk.decision}</TableCell>
                <TableCell component="th">{row._date_and_time_str}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                backIconButtonProps={{
                  color: appTheme === "light" ? "black" : "#E9EBED",
                }}
                nextIconButtonProps={{
                  color: appTheme === "light" ? "black" : "#E9EBED",
                }}
                rowsPerPageOptions={[5, 10, 20, 30, 50]}
                count={total}
                rowsPerPage={query.rows_per_page}
                page={query.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <div className="flex-1 text-gray-700 text-center px-4 py-2 m-2">
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadReport}
          >
            Export CSV
          </Button>
        </div>
        {generateReport.open ? (
          <GenerateReportModal
            open={generateReport.open}
            filename={generateReport.filename}
            handleClose={handleCloseGenerateReport}
            Api={UserSessionApi}
            props_query={query}
            api_action="getSessions"
            headers={generateReport.headers}
          />
        ) : (
          ""
        )}
      </TableContainer>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {snackBar.result ? (
          <Alert
            onClose={handleSnackbarClose}
            elevation={6}
            severity="success"
            variant="filled"
          >
            {snackBar.message}
          </Alert>
        ) : (
          <Alert
            onClose={handleSnackbarClose}
            elevation={6}
            severity="error"
            variant="filled"
          >
            {snackBar.message}
          </Alert>
        )}
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default RestrictedUserSessions;
