import React, { Component, Suspense } from "react";
import FourOhFour from "./components/errors/FourOhFour";
import routes from "./utils/routes";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivateContainer from "./components/global/partials/PrivateContainer";
import PublicContainer from "./components/global/partials/PublicContainer";
import LoadScreen from "./components/global/partials/LoadScreen";
import "./App.css"

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Suspense fallback={<LoadScreen />}>
					<Switch>
						{routes.map(({ path, exact, private_route, ...rest }) => (
							<Route
								key={path}
								path={path}
								exact={exact}
								render={(props) => {
									return private_route ? (
										<PrivateContainer {...props} {...rest} />
									) : (
										<PublicContainer {...props} {...rest} />
									);
								}}
							/>
						))}

						<Route
							render={(props) => (
								<PublicContainer component={FourOhFour} {...props} />
							)}
						/>
					</Switch>
				</Suspense>
			</BrowserRouter>
		);
	}
}

export default App;
