import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import { ThemeContext } from '../../../context/theme';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

export default function LoadScreen() {
    const classes = useStyles();
    const [appTheme] = useContext(ThemeContext);

    return (
        <div className={"h-screen w-screen content-center" + (appTheme === 'light' ? ' bg-gray-200' : ' bg-gray-900')}>
            <div className="flex items-center h-full">
                <div className="flex-1 text-gray-700 text-center px-4 py-2 m-2"></div>
                <div className="flex-1 text-gray-700 text-center px-4 py-2 m-2">
                    <div className="self-center">
                        <CircularProgress />
                    </div>
                </div>
                <div className="flex-1 text-gray-700 text-center px-4 py-2 m-2"></div>
            </div>
        </div>
    );
}

