import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Backdrop,
  CircularProgress,
  makeStyles,
  Button,
} from "@material-ui/core";
import RSelect from "react-select";
import { CSVLink } from "react-csv";
import Picker from "./metrics_comps/Picker";
import Alert from "@material-ui/lab/Alert";
import MetricsApi from "../../api/MetricsApi";
import moment from "moment";

const PLATFORM_OPTIONS = [
  { value: "all", label: "All" },
  { value: "coinpass", label: "COINPASS" },
  { value: "oandacrypto", label: "OANDA CRYPTO" },
];

const WEEK_TABLE_HEADERS = [
  "Week",
  "Initial Week (Date)",
  "Applications Completed",
  "Deposit New Client",
  "Deposit Existing Client",
  "New Traded Account",
  "Total Revenue",
];

const MONTH_TABLE_HEADERS = [
  "Month",
  "Applications Completed",
  "Deposit New Client",
  "Deposit Existing Client",
  "New Traded Account",
  "Total Revenue",
];

const CUSTOM_DATE_TABLE_HEADERS = [
  "From",
  "To",
  "Applications Completed",
  "Deposit New Client",
  "Deposit Existing Client",
  "New Traded Account",
  "Total Revenue",
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const formatCSVData = (data, range) => {
  return data.map((row) => {
    const { label, initial_date, range_start, range_end, counts } = row;
    const countsArray = counts ? Object.values(counts) : [];

    if (range === "weekly") return [label, initial_date, ...countsArray];
    else if (range === "monthly") return [label, ...countsArray];
    else return [range_start, range_end, ...countsArray];
  });
};

export default function PerformanceMetrics() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [picker_open, setPickerOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    result: false,
    message: "",
  });

  const [range, setRange] = useState("weekly");
  const [scope, setScope] = useState("all");
  const [platform, setPlatform] = useState("all");

  const [pickerValues, setPickerValues] = useState({
    date_to: new Date().toISOString(),
    date_from: new Date().toISOString(),
  });

  const [extraRanges, setExtraRange] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  const handleScopeChange = (e = {}) => {
    const scope = e.target?.value;
    setScope(scope);
    switch (scope) {
      case "all":
        setPlatform("all");
        break;
      case "b2b2c":
      case "corporate":
        setPlatform("coinpass");
        break;
      default:
      case "retail":
        setPlatform("all");
        break;
    }
  };

  const handleRangeChange = (e = {}) => {
    const { value } = e.target || {};
    if (value !== "custom") {
      setRange(e.target?.value || "weekly");
    } else {
      setPickerOpen(true);
    }
  };

  const handlePickerChange = (key, e) => {
    const isDate = key?.startsWith("date");
    const value = isDate ? e : e.target.value;

    setPickerValues((p) => ({
      ...p,
      [key]: isDate ? new Date(value).toISOString() : value,
    }));
  };

  const handlePickerSubmit = () => {
    setPickerOpen(false);

    const from = moment(pickerValues.date_from).format("YYYY-MM-DD");
    const to = moment(pickerValues.date_to).format("YYYY-MM-DD");
    const range_key = `custom:${from}:${to}`;
    setRange(`custom:${from}:${to}`);
    setExtraRange((p) => [range_key]);

    setRange(range_key);
  };

  const handleAlertClose = () => setAlert((p) => ({ ...p, open: false }));

  const showAlert = (message = "", success = false) =>
    setAlert((p) => ({ ...p, open: true, result: success, message }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await MetricsApi.oanda({
          scope,
          platform,
          table_range: range,
          section: "performance_table",
        });
        if (range === "weekly" || range === "monthly") {
          setRows(data.data);
          setHeaders(
            range === "weekly" ? WEEK_TABLE_HEADERS : MONTH_TABLE_HEADERS
          );
        } else {
          setRows([data.data]);
          setHeaders(CUSTOM_DATE_TABLE_HEADERS);
        }
      } catch (error) {
        showAlert({ message: error.message || "Something went wrong" });
      }
      setIsLoading(false);
    };
    fetchData();
  }, [scope, platform, range]);

  return (
    <div className="w-full h-full">
      <div className="flex justify-between mb-4">
        <div className="w-1/3 relative">
          <div className="grid grid-cols-5 gap-2 items-center">
            <Button variant="contained" color="primary">
              <CSVLink
                data={[headers, ...(formatCSVData(rows, range) || [])]}
                filename={`performance_metrics_${range}.csv`}
                className="col-span-2 flex-end self-end"
                disabled={rows.length === 0}
              >
                Generate Report
              </CSVLink>
            </Button>

            {scope === "retail" && (
              <div className="col-span-3">
                <Typography>Platform</Typography>
                <RSelect
                  placeholder="Select Platform..."
                  onChange={(e) => setPlatform(e.value)}
                  options={PLATFORM_OPTIONS}
                  value={
                    PLATFORM_OPTIONS.find((i) => i.value === platform) ?? ""
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div className="w-1/4 relative">
          <div className="grid grid-cols-3 gap-2 items-center">
            <div className="col-span-1">
              <Typography className="text-right">Scope:</Typography>
            </div>
            <div className="col-span-2">
              <Select value={scope} onChange={handleScopeChange} label="Scope">
                <MenuItem value="all"> All </MenuItem>
                <MenuItem value="retail"> Retail </MenuItem>
                <MenuItem value="corporate"> Corporate </MenuItem>
                <MenuItem value="b2b2c"> B2B2C </MenuItem>
              </Select>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 items-center">
            <div className="col-span-1">
              <Typography className="text-right">Range:</Typography>
            </div>
            <div className="col-span-2">
              <Select value={range} onChange={handleRangeChange} label="Range">
                <MenuItem value="weekly"> Weekly</MenuItem>
                <MenuItem value="monthly"> Monthly</MenuItem>
                <MenuItem value="custom"> Custom (Local Timezone)</MenuItem>
                {extraRanges.map((range) => (
                  <MenuItem value={range}> {range}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>

        {picker_open ? (
          <Picker
            open={picker_open}
            values={pickerValues}
            handlePickerClose={() => setPickerOpen(false)}
            handleChange={handlePickerChange}
            handleSubmit={handlePickerSubmit}
            showTradeUnit={false}
            showCompareAgainst={false}
          />
        ) : null}
      </div>

      <Table
        id="performance-metrics"
        className="w-full table-fixed"
        size="small"
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell className="font-semi-bold my-2">{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className="text-xs break-all">
          {rows?.map((row = {}) => (
            <TableRow>
              {row.label && <TableCell>{row.label}</TableCell>}
              {row.initial_date && <TableCell>{row.initial_date}</TableCell>}
              {row.range_start && <TableCell>{row.range_start}</TableCell>}
              {row.range_end && <TableCell>{row.range_end}</TableCell>}
              <TableCell>{row.counts.applications_completed}</TableCell>
              <TableCell>{row.counts.deposit_new_client}</TableCell>
              <TableCell>{row.counts.deposit_existing_client}</TableCell>
              <TableCell>{row.counts.new_traded_account}</TableCell>
              <TableCell>{row.counts.total_revenue_recorded}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={alert.result ? "success" : "error"}
          onClose={handleAlertClose}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
