import { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import moment from "moment-timezone";
import TableFilters from "../global/filters/TableFilters";
import SimpleTable from "../global/tables/SimpleTable";
import StripeAPI from "../../api/Stripe";

const headers = [
  "UID",
  "Payment method",
  "Status",
  "Amount",
  "Currency",
  "Date/Time",
  "Decline reason",
];

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: "1600px",
    margin: "auto",
    overflow: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  loading: {
    marginLeft: theme.spacing(5),
  },
  paginationContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "flex-end",
    padding: "15px 30px",
  },
  refreshIcon: {
    border: "2px solid grey",
    borderRadius: "4px",
  },
}));

const FailedStripeDeposits = () => {
  const classes = useStyles();
  const [query, setQuery] = useState({
    search: "",
    next_page: null,
  });
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagesIndex, setPagesIndex] = useState(new Map());

  useEffect(() => {
    setLoading(true);
    setFilters([
      { key: "search", value: "", label: "Search by", type: "search" },
      {
        key: "from",
        value: query.from,
        label: "From",
        show: true,
        type: "date_picker",
        format: "dd/MM/yyyy",
      },
      {
        key: "to",
        value: query.to,
        show: true,
        label: "To",
        type: "date_picker",
        format: "dd/MM/yyyy",
      },
    ]);

    StripeAPI.getFailedDeposits({
      ...query,
    })
      .then((res) => {
        setPagesIndex((prev) => {
          return prev.set(currentPage + 1, res.data.next_page);
        });
        const rows = res.data.data.map((row) => ({
          UID: row.userId,
          paymentMethod: row.card_brand,
          status: row.status,
          amount: row.amount,
          currency: row.currency,
          dateTime: moment(row.created_at)
            .tz("GB")
            .format("YYYY-MM-DD hh:mm:ss A"),
          declineReason: row.error_message,
        }));
        setRows(rows);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleQueryChange = (field, e) => {
    let value = null;

    switch (field.type) {
      case "date_picker":
        value = e;
        break;

      default:
        value = e.target.value;
    }
    setCurrentPage(1);

    setQuery((prevState) => {
      return {
        ...prevState,
        page: field.type === "search" ? 1 : prevState.page,
        [field.key]: value,
      };
    });
  };

  return (
    <Paper className={classes.paper}>
      <TableFilters
        filters={filters}
        extra_buttons={[]}
        is_loading={loading}
        handleQueryChange={handleQueryChange}
      />
      <SimpleTable rows={rows} query={query} headers={headers} hidePagination />

      <Grid container className={classes.paginationContainer}>
        <IconButton
          className={classes.refreshIcon}
          disabled={currentPage === 1}
          onClick={() => {
            setQuery({
              ...query,
              next_page: pagesIndex.get(currentPage - 1),
            });
            setCurrentPage((prev) => prev - 1);
          }}
        >
          <ChevronLeft />
          <Typography>Previous</Typography>
        </IconButton>
        <IconButton
          className={classes.refreshIcon}
          disabled={!pagesIndex.get(currentPage + 1)}
          onClick={() => {
            setQuery({
              ...query,
              next_page: pagesIndex.get(currentPage + 1),
            });
            setCurrentPage((prev) => prev + 1);
          }}
        >
          <Typography>Next</Typography>
          <ChevronRight />
        </IconButton>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

export default FailedStripeDeposits;
