import * as BACKEND_URLS from "../utils/BACKEND_URLS";
import Axios from "../utils/AuthAxios";

const StripeAPI = {
  async getFailedDeposits(params) {
    return await Axios.get(`${BACKEND_URLS.CP2API}/stripe-failed-payments`, {
      params,
    });
  },
};

export default StripeAPI;
