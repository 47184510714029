import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
	createMuiTheme,
	ThemeProvider,
	makeStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Navigator from "./Navigator";
import Header from "./Header";
import { ThemeContext } from "../../../context/theme";
import { Typography, Link } from "@material-ui/core";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{"Copyright © "}
			<Link color="inherit" href="https://material-ui.com/">
				Coinpass
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}


function PublicContainer({ component: Component, ...props }) {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [appTheme] = useContext(ThemeContext);

	let theme = createMuiTheme({
		palette: {
			mode: appTheme,
			primary: {
				light: appTheme === 'light' ? "#63ccff" : '#88B4F6',
				main: appTheme === 'light' ? "#009be5" : '#88B4F6',
				dark: appTheme === 'light' ? "#006db3" : '#669DF7',
				contrastText: appTheme === 'light' ? undefined : '#212023',
			},
			background: {
				paper: appTheme === 'light'? 'white': '#2F3033',
			}
		},
		typography: {
			h5: {
				fontWeight: 500,
				fontSize: 26,
				letterSpacing: 0.5,
			},
		},
		shape: {
			borderRadius: 8,
		},
		props: {
			MuiTab: {
				disableRipple: true,
			},
		},
		mixins: {
			toolbar: {
				minHeight: 48,
			},
		},
	});

	theme = {
		...theme,
		overrides: {
			MuiTypography: {
				h5: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
				colorTextSecondary: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
				root: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				}
			},
			MuiFormLabel: {
				root: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
			},
			MuiInputBase: {
				input: {
					background: appTheme === 'light' ? undefined: '#2A2A2F',
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
			},
			MuiDrawer: {
				paper: {
					backgroundColor: appTheme === 'light' ? "#18202c" : '#22223b',
				},
			},
			MuiLink: {
				root: {
					color: appTheme === 'light' ? undefined : '#88B4F6',
				}
			},
			MuiButton: {
				text: {
					color: appTheme === 'light' ? undefined : '#E9EBED',
				},
				label: {
					textTransform: "none",
				},
				contained: {
					boxShadow: "none",
					"&:active": {
						boxShadow: "none",
					},
				},
			},
			MuiTabs: {
				root: {
					marginLeft: theme.spacing(1),
				},
				indicator: {
					height: 3,
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
					backgroundColor: theme.palette.common.white,
				},
			},
			MuiTab: {
				root: {
					textTransform: "none",
					margin: "0 16px",
					minWidth: 0,
					padding: 0,
					[theme.breakpoints.up("md")]: {
						padding: 0,
						minWidth: 0,
					},
				},
			},
			MuiIconButton: {
				root: {
					padding: theme.spacing(1),
				},
			},
			MuiTooltip: {
				tooltip: {
					borderRadius: 4,
				},
			},
			MuiDivider: {
				root: {
					backgroundColor: appTheme === 'light' ? "#404854" : '#E9EBED',
				},
			},
			MuiListItemText: {
				primary: {
					fontWeight: theme.typography.fontWeightMedium,
				},
			},
			MuiListItemIcon: {
				root: {
					color: "inherit",
					marginRight: 0,
					"& svg": {
						fontSize: 20,
					},
				},
			},
			MuiAvatar: {
				root: {
					width: 32,
					height: 32,
				},
			},
		},
	};

	const drawerWidth = 256;

	const useStyles = makeStyles({
		root: {
			display: "flex",
			minHeight: "100vh",
		},
		drawer: {
			[theme.breakpoints.up("sm")]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		app: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
		},
		main: {
			flex: 1,
			padding: theme.spacing(6, 4),
			background: appTheme === 'light' ? "#eaeff1" : '#2F3033',
		},
		footer: {
			padding: theme.spacing(2),
			background: appTheme === 'light' ? "#eaeff1" : '#2F3033',
		},
	});
	const classes = useStyles();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<CssBaseline />
				<div className={classes.app}>
					<main className={classes.main}>
						<Component {...props} />
					</main>
					<footer className={classes.footer}>
						<Copyright />
					</footer>
				</div>
			</div>
		</ThemeProvider>
	);
}

PublicContainer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default PublicContainer;
