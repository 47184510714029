import AdminApi from "../../api/AdminApi";
import { UI } from "../ActionTypes";

export const getPinnedPages = () => async (dispatch) => {
  try {
    dispatch({ type: UI.GET_PINNED_PAGES });
    const { data } = await AdminApi.getPinnedPages();
    const tabs = data.data.tabs;
    dispatch({
      type: UI.GET_PINNED_PAGES_SUCCESS,
      payload: tabs,
    });
  } catch (error) {
    console.log(error)
    dispatch({
      type: UI.ERROR,
      payload: error?.message || "Something went wrong!",
    });
  }
};

export const toggleAddToPinnedPages = (page) => async (dispatch, getState) => {
  try {
    dispatch({ type: UI.TOGGLE_ADD_TO_PINNED_PAGES });
    const state = getState();
    const pageExists = state.ui.pinnedPages.includes(page);
    let newPinnedPagesToggle;

    if (pageExists) {
      newPinnedPagesToggle = state.ui.pinnedPages.filter(
        (currentPage) => currentPage !== page
      );
    } else {
      newPinnedPagesToggle = [...state.ui.pinnedPages, page];
    }

    await AdminApi.updatePinnedPages(newPinnedPagesToggle);

    dispatch({
      type: UI.TOGGLE_ADD_TO_PINNED_PAGES_SUCCESS,
      payload: newPinnedPagesToggle,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: UI.ERROR,
      payload: error?.message || "Something went wrong!",
    });
  }
};