import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/theme";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import StarBorder from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import {
	Toolbar,
	Typography,
	Menu,
	MenuItem,
	Switch,
	Button,
	Tooltip,
	CircularProgress,
	Snackbar,
} from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import Cookies from "js-cookie";

import * as URLS from "../../../utils/URLS";
import { useDispatch, useSelector } from "react-redux";
import { toggleAddToPinnedPages } from "../../../redux/actions/UIActions";
import { useLocation } from 'react-router-dom';
import { Alert } from "@material-ui/lab";
const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
	secondaryBar: {
		zIndex: 0,
	},
	menuButton: {
		marginLeft: -theme.spacing(1),
	},
	iconButtonAvatar: {
		padding: 4,
	},
	link: {
		textDecoration: "none",
		color: lightColor,
		"&:hover": {
			color: theme.palette.common.white,
		},
	},
	button: {
		borderColor: lightColor,
	},
});

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));
function Header(props) {
	const { classes, onDrawerToggle } = props;
	const location = useLocation()
	const dispatch = useDispatch();
	const { pinnedPages, loading, message, isError } = useSelector((state) => state.ui);
	const [openAlert, setOpenAlert] = useState(false)
	const [appTheme, setAppTheme] = useContext(ThemeContext);
	const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    setOpenAlert(!!message);
  }, [message]);


	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleProfile = () => {
		setAnchorEl(null);

		props.history.push(URLS.PROFILE);
	};

	const handleLogout = async () => {
		setAnchorEl(null);

		Cookies.set("coinpass_user_access_token", "");

		Cookies.set("coinpass_user", "");

		Cookies.set("user_level", "")

		props.history.push(URLS.HOME);
	};

	const handleThemeSwitch = (e = {}) => {
		const _theme = e.target?.checked ? 'dark' : 'light';
		setAppTheme(_theme);
	};

	return (
		<React.Fragment>
			<AppBar color="primary" position="sticky" elevation={0}>
				<Toolbar>
					<Grid container spacing={1} alignItems="center">
						<Hidden smUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={onDrawerToggle}
									className={classes.menuButton}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>
						<Grid item xs>
							<Typography variant="h5" component="h1">
								{props.title}
							</Typography>
						</Grid>
						<Grid item xs />
            <Grid item >
              <Button
								className="outline-none focus:outline-none"
                onClick={() =>
                  dispatch(toggleAddToPinnedPages(location.pathname))
                }
								disabled={loading}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={18} />
                ) : pinnedPages?.includes(location.pathname) ? (
                  <Tooltip title="Remove from pinned pages">
                    <StarIcon className="text-yellow-500" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Add to pinned pages">
                    <StarBorder />
                  </Tooltip>
                )}
              </Button>
            </Grid>
						<Grid item>
						<MaterialUISwitch 
							onChange={handleThemeSwitch} 
							checked={appTheme === 'dark'}
						/>
						</Grid>
						<Grid item>
							<IconButton
								color="inherit"
								onClick={handleClick}
								className={classes.iconButtonAvatar}
							>
								<Avatar
									src="/static/images/avatar/1.jpg"
									alt={
										Cookies.get("coinpass_user")
											? Cookies.get("coinpass_user").toLocaleUpperCase()
											: ""
									}
								/>
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem key={"Profile"} onClick={handleProfile}>
									Profile
								</MenuItem>
								<MenuItem key={"Logout"} onClick={handleLogout}>
									Logout
								</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          elevation={6}
          variant='filled'
          severity={isError? 'error' : 'success' }
          onClose={() => setOpenAlert(false)}
        >
          {message}
        </Alert>
      </Snackbar>
		</React.Fragment>
	);
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
